html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	font-display: fallback;
	vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}

body {
	line-height: 1;
}

blockquote,
q {
	quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
	content: '';
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

/*Remove some browser defaults for IE, Edge, Safari*/

input[type='text']::-ms-clear {
	display: none;
}

select::-ms-expand {
	display: none;
}

input::-webkit-contacts-auto-fill-button {
	display: none !important;
	pointer-events: none;
	position: absolute;
	right: 0;
	visibility: hidden;
}

*::before,
*::after {
	box-sizing: inherit;
}
*:focus {
	outline: 2px dotted #333333;
	outline-offset: 2px;
}

html {
	box-sizing: border-box;
	scroll-behavior: smooth;
}

html [type='button'] {
	appearance: none;
}

body.noScroll {
	position: fixed;
	width: 100%;
	height: 100%;
}

/**
	* Form elements don't inherit font settings.
	* https://stackoverflow.com/questions/26140050/why-is-font-family-not-inherited-in-button-tags-automatically
	*/
html,
body,
input,
select,
optgroup,
textarea,
button {
	font-weight: normal;
	font-feature-settings: 'kern';
	font-family: system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow-x: hidden;
	text-rendering: optimizeLegibility;
}

button {
	cursor: pointer;
}

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border: 0;
}

.sr-only:active,
.sr-only:focus {
	 position: static;
	 width: auto;
	 height: auto;
	 overflow: visible;
	 clip: auto;
	 white-space: normal;
 }

a {
	color: inherit;
	font-weight: 500;
	text-decoration: none;
}
a:focus, a:hover {
   text-decoration: underline;
}

*::selection {
	background-color: #ffd200;
}
